.between {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.rowgap {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

@font-face {
    font-family: 'Dongle';
    font-weight: lighter;
    src: url('../fonts/Dongle-Light.ttf') format('truetype');
    font-display: block;
  }
  
  @font-face {
    font-family: 'Dongle';
    font-weight: normal;
    src: url('../fonts/Dongle-Regular.ttf') format('truetype');
    font-display: block;
  }
  
  @font-face {
    font-family: 'Dongle';
    font-weight: bold;
    src: url('../fonts/Dongle-Bold.ttf') format('truetype');
    font-display: block;
  }
  
  .dongle {
    font-family: 'Dongle';
  }