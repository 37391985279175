.burguer-button__button {
    display: grid;
    gap: .6rem;
    width: auto;
    justify-content: center;
}
.burguer-button__line1, .burguer-button__line2 {
    transition: all .55s ease-in-out;
}

.open .burguer-button__line1 {
    transform: rotate(140deg) translateX(.1rem) translateY(-.3rem);
}

.open .burguer-button__line2 {
    transform: rotate(-138deg) translateX(.25rem) translateY(.4rem);
}